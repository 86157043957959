import React, { useRef, useState, useContext } from 'react';
import {
  Typography,
  TextField,
  Box,
  Link,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../mui/button';
import FieldsWrapper from '../form/fields-wrapper';
import ReCAPTCHA from 'react-google-recaptcha';
import ThemeContext from '../../contexts/theme-context';
import { sendMail } from '../../services/mail';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: (appBarVisible) => ({
    top: theme.spacing(3) + (appBarVisible ? 150 : 0),
    transition: `top ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut}`,
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 400,
      margin: '0 auto',
    },
    '& button': {
      borderWidth: 2,
      borderColor: theme.palette.common.white,
      color: theme.palette.common.white,

      [theme.breakpoints.up('lg')]: {
        minWidth: 260,
      },
    },
  }),
  recaptcha: {
    marginBottom: 20,
    transform: 'scale(1)',
    transformOrigin: '0 0',
    [theme.breakpoints.down('md')]: {
      transform: 'scale(0.8)',
    },
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(1)',
    },
  },
  error: { color: theme.palette.warning.main, lineHeight: '15px' },
  wrapper: {
    display: 'inline-block',
    marginTop: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.violet.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CareersForm: React.FC = () => {
  const [recaptchaError, setrecaptchaError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { appBarVisible } = useContext(ThemeContext);
  const classes = useStyles(appBarVisible);
  const siteKey =
    process.env.NODE_ENV === 'production'
      ? '6LdDdwsUAAAAAEBGoOeXYGojxDmc4Ol4EwVn7kE5'
      : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

  const recaptchaRef = useRef<any>(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onRecaptchaChange = async () => {
    if (!recaptchaRef.current.getValue()) {
      setrecaptchaError(true);
    } else {
      setrecaptchaError(false);
    }
  };

  const onSubmit = async (values: any) => {
    if (!recaptchaRef.current.getValue()) {
      setrecaptchaError(true);
    } else {
      setrecaptchaError(false);
      const from = values.email;
      const subject = 'New application from Careers page';
      const bodyParts: string[] = [
        `<p><strong>From:</strong> ${values.firstName} ${values.lastName} <${values.email}></p>`,
      ];
      bodyParts.push(
        `<p><strong>Description:</strong> ${values.description}</p>`
      );
      const message = bodyParts.join('');

      setSubmitting(true);

      sendMail('info@marvelandsnap.com', from, subject, message)
        .then(() => {
          setSubmitSuccess(true);
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitFailure(true);
          setSubmitting(false);
        });
    }
    await new Promise((r) => setTimeout(r, 500));
  };

  let FormContent;

  if (submitSuccess) {
    FormContent = (
      <Box>
        <Typography
          variant="subtitle1"
          component="p"
          gutterBottom={true}
          style={{ color: 'white' }}
        >
          Thank you for the application!
        </Typography>
        <Typography
          variant="body2"
          gutterBottom={true}
          style={{ color: 'white' }}
        >
          We will look over your message and get back in touch with you very
          shortly.
        </Typography>
        <Typography variant="body2" style={{ color: 'white' }}>
          Have a great day!
        </Typography>
      </Box>
    );
  } else if (submitFailure) {
    FormContent = (
      <Box>
        <Typography
          variant="subtitle1"
          component="p"
          style={{ color: 'white' }}
          gutterBottom={true}
        >
          Something went wrong!
        </Typography>
        <Typography variant="body2" style={{ color: 'white' }}>
          Your request could not be completed. Please contact{' '}
          <Link href="mailto:info@marvelandsnap.com" style={{ color: 'white' }}>
            info@marvelandsnap.com
          </Link>{' '}
          for assistance.
        </Typography>
      </Box>
    );
  } else {
    FormContent = (
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldsWrapper mode="white">
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, ref } }) => (
              <TextField
                id="firstName"
                label="first name"
                variant="outlined"
                onChange={onChange}
                fullWidth
                inputRef={ref}
              />
            )}
            rules={{ required: true }}
          />

          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, ref } }) => (
              <TextField
                id="lastName"
                label="last name"
                variant="outlined"
                onChange={onChange}
                fullWidth
                inputRef={ref}
              />
            )}
            rules={{ required: true }}
          />

          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, ref } }) => (
              <TextField
                id="email"
                label="email"
                variant="outlined"
                onChange={onChange}
                fullWidth
                inputRef={ref}
              />
            )}
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'invalid email address',
              },
            }}
          />

          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, ref } }) => (
              <TextField
                id="description"
                label="tell us about yourself"
                variant="outlined"
                onChange={onChange}
                fullWidth
                multiline={true}
                minRows={15}
                maxRows={30}
                inputRef={ref}
              />
            )}
            rules={{ required: true }}
          />

          <div className={classes.recaptcha}>
            <ReCAPTCHA
              sitekey={siteKey}
              ref={recaptchaRef}
              onChange={onRecaptchaChange}
            />
          </div>

          {errors.firstName ||
          errors.lastName ||
          errors.description ||
          recaptchaError ? (
            <Typography style={{ marginBottom: 20 }} className={classes.error}>
              All fields are mandatory
            </Typography>
          ) : errors.email ? (
            <Typography style={{ marginBottom: 20 }} className={classes.error}>
              Please enter a valid email address
            </Typography>
          ) : null}
        </FieldsWrapper>

        <div className={classes.wrapper}>
          <Button
            size="large"
            type="submit"
            variant="outlined"
            disabled={submitting}
          >
            Apply now
          </Button>
          {submitting && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </form>
    );
  }

  return (
    <Box className={classes.root}>
      <Typography variant="h2">apply now</Typography>
      {FormContent}
    </Box>
  );
};

export default CareersForm;
