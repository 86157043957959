import React from 'react';
import Layout from '../components/global/layout';
import SEO from '../components/seo';
import PageBanner from '../components/layouts/page-banner';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import LightMode from '../components/global/light-mode';
import VerticalPadding from '../components/global/vertical-padding';
import theme from '../lib/theme';
import CareersForm from '../components/layouts/careers-form';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.violet.main,
    [theme.breakpoints.up('md')]: {
      '&::after': {
        backgroundColor: theme.palette.primary.main,
        content: '""',
        display: 'block',
        position: 'absolute',
        right: '0',
        top: 0,
        width: '2vw',
        height: '100%',
        zIndex: 1,
      },
    },
  },
  genericBgrnd: {
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  VerticalPadding: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(20),
      height: '100%',
    },
  },
  contentCol: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& h2': {
      color: theme.palette.common.white,
      fontFamily: 'Visby CF, sans-serif',
    },
  },
  list: {
    listStyle: 'disc',
    paddingLeft: theme.spacing(4),
    marginTop: 0,
    marginBottom: theme.spacing(4),

    '&:last-child': {
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
  },
  applyCol: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    zIndex: 2,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 'calc(2vw + 16px)',
    },
    '& h2': {
      color: theme.palette.common.white,
      marginBottom: theme.spacing(3),
    },
  },
}));

const Careers: React.FC = () => {
  const classes = useStyles();

  return (
    <Layout>
      <SEO
        title="Careers"
        description="Marvel us with your web development skills and apply for a career at Marvel &amp; Snap today."
      />

      <PageBanner
        heading="We’re always looking for talent that marvels us"
        imageSrc="careers-banner.jpg"
      />
      <LightMode>
        <VerticalPadding lg>
          <Container>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item xs={12} md={3}>
                <Typography variant="h2">work where you matter</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="subtitle1"
                  component="p"
                  gutterBottom={true}
                >
                  We believe the manner in which something is built is as much a
                  part of the solution as the visual design. That’s why our team
                  members are involved in projects from the initial discovery
                  and strategy all the way through execution.
                </Typography>
                <Typography variant="subtitle1" component="p">
                  We don’t make decisions involving user experience, visual
                  design, or architecture in a void. Our team members
                  collaborate as active decision-makers throughout all of our
                  client relationships — from handshake to launch and ongoing
                  management.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </VerticalPadding>
      </LightMode>
      <Box className={classes.root}>
        <Container maxWidth={false} className={classes.genericBgrnd}>
          <Grid container justify="space-around">
            <Grid item xs={12} md={7} lg={6} className={classes.contentCol}>
              <VerticalPadding className={classes.VerticalPadding}>
                <Box>
                  <Typography variant="h2" style={{ marginBottom: 30 }}>
                    We’re hiring Web Developers
                  </Typography>
                  <Typography
                    variant="h3"
                    color="textSecondary"
                    style={{ marginBottom: 30 }}
                  >
                    Daily Responsibilities
                  </Typography>
                  <Typography
                    component="ul"
                    variant="body2"
                    style={{ marginBottom: 30 }}
                  >
                    <li>
                      A large majority of the time you will be responsible for
                      managing customer support tickets assigned to you.
                    </li>
                    <li>
                      Build, maintain, and upgrade customer WordPress sites
                    </li>
                    <li>
                      Collaborate with your Team Lead to successfully fulfill
                      project objectives
                    </li>
                    <li>
                      General web development from full-site builds to smaller
                      support tasks with an equal focus on PHP and front-end
                      work
                    </li>
                    <li>Deep WordPress them customization</li>
                    <li>
                      Third-party integrations (e.g. Stripe, Marketo, RESTful
                      APIs in general, etc.)
                    </li>
                    <li>
                      Assist with internal development operations, as well as in
                      the development of internal tools
                    </li>
                  </Typography>
                  <Typography
                    variant="h3"
                    color="textSecondary"
                    style={{ marginBottom: 30 }}
                  >
                    Required Skills
                  </Typography>
                  <Typography
                    component="ul"
                    variant="body2"
                    style={{ marginBottom: 30 }}
                  >
                    <li>
                      3+ years of experience in a similar development role with
                      solid experience building custom WordPress themes using
                      PHP
                    </li>
                    <li>
                      3+ years of working experience and ability with HTML, CSS,
                      and JavaScript
                    </li>
                    <li>
                      Ability to be a quick learner and to work well in a team
                      environment: supportive, collaborative, cooperative, and
                      customer service-oriented
                    </li>
                    <li>
                      Excellent verbal and written communication abilities
                    </li>
                    <li>Comfortable with SCSS/Sass, Gulp, and Git</li>
                    <li>Taking great pride in the work you do</li>
                  </Typography>
                  <Typography
                    variant="h3"
                    color="textSecondary"
                    style={{ marginBottom: 30 }}
                  >
                    Bonus Points:
                  </Typography>
                  <Typography
                    component="ul"
                    variant="body2"
                    style={{ marginBottom: 30 }}
                  >
                    <li>Experience using Timber/Twig</li>
                    <li>Experience Shopify Online Store 2.0 themes</li>
                    <li>
                      Experience with WCAG 2.0 Level AA and/or AODA compliance
                    </li>
                    <li>Familiarity with Pantheon.io</li>
                  </Typography>
                  <Typography
                    variant="h3"
                    color="textSecondary"
                    style={{ marginBottom: 30 }}
                  >
                    Additional Info:
                  </Typography>
                  <Typography
                    component="ul"
                    variant="body2"
                    style={{ marginBottom: 30 }}
                  >
                    <li>Remote work</li>
                    <li>Full-time salaried position</li>
                  </Typography>
                  <Typography
                    variant="h3"
                    color="textSecondary"
                    style={{ marginBottom: 30 }}
                  >
                    Benefits
                  </Typography>
                  <Typography component="ul" variant="body2">
                    <li>
                      We pay at or above market salary in most cases and
                      evaluate a lot of research to make sure everyone is paid
                      well.
                    </li>
                    <li>
                      Work from anywhere within Canada, provided you have a
                      minimum of 6 hours overlap with the team.
                    </li>
                    <li>Healthcare Spending Account</li>
                    <li>
                      2 weeks of vacation, in addition to our holiday shutdown
                      in December.
                    </li>
                  </Typography>
                </Box>
              </VerticalPadding>
            </Grid>
            <Grid item xs={12} md={5} lg={5} className={classes.applyCol}>
              <VerticalPadding className={classes.VerticalPadding}>
                <CareersForm />
              </VerticalPadding>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default Careers;
